import { Inject, Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { ContactInfoField } from 'models/contact-info-fields'
import { HubtypeBot } from 'models/hubtype-bot'
import { HubtypeFolder } from 'models/hubtype-folder'
import { HubtypeProject } from 'models/hubtype-project'
import { HubtypeTemplate } from 'models/hubtype-template'
import { HubtypeUser } from 'models/hubtype-user'
import { Observable, forkJoin, of } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'
import * as organizationActions from '../../actions/organization'
import { FeatureFlag } from '../../constants/feature-flags'
import {
  HubtypeOrganization,
  HubtypeOrganizationComplete,
  MyOrganization,
} from '../../models/hubtype-organization'
import * as fromRoot from '../../reducers'
import { Utilities } from '../../shared/utilities'
import { ConverterService } from '../converter.service'
import { ContactInfoFieldFilter } from './../../models/contact-info-fields'
import { BotService } from './bot.service'
import { HubtypeApiService } from './hubtype-api.service'
import { ProjectService } from './project.service'
import { TemplateService } from './template.service'
import { UserService } from './user.service'

type OrganizationFieldsResponses = [
  MyOrganization,
  HubtypeTemplate[],
  HubtypeFolder[],
  HubtypeBot[],
  HubtypeUser[],
  HubtypeProject[],
]
@Injectable()
export class OrganizationService {
  constructor(
    protected store: Store<fromRoot.State>,
    @Inject('apiService') private apiService: HubtypeApiService,
    @Inject('userService') private userService: UserService,
    @Inject('Utilities') private utilities: Utilities,
    @Inject('convertService') private convertService: ConverterService,
    @Inject('templateService') private templateService: TemplateService,
    @Inject('botService') private botService: BotService,
    @Inject('projectService') private projectService: ProjectService
  ) {}

  getMyOrganization(): Observable<MyOrganization> {
    return this.apiService
      .get(`/my-organization/`, null, 'v1')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            MyOrganization
          )
        )
      )
  }

  getAndAssembleHubtypeOrganization(): Observable<HubtypeOrganizationComplete> {
    const parallelRequests = [
      this.getMyOrganization(),
      this.templateService.getAllpaginatedTemplates(),
      this.templateService.getAllpaginatedTemplateFolders(),
      this.botService.getAllpaginatedBots(),
      this.userService.getAllPaginatedUsers(),
      this.projectService.getAllPaginatedProjects(),
    ]
    return forkJoin(parallelRequests).pipe(
      first(),
      map((responses: OrganizationFieldsResponses) =>
        this.assembleOrganization(responses)
      )
    )
  }

  hasFeatureFlag(flag: FeatureFlag): Observable<boolean> {
    return this.store.select(fromRoot.getOrganization).pipe(
      map(org => {
        if (!org || !org.feature_flags_json) {
          return false
        }
        return Boolean(org.feature_flags_json[flag])
      })
    )
  }

  update(
    organization: HubtypeOrganization
  ): Observable<HubtypeOrganizationComplete> {
    const body = organization
    return this.apiService
      .put(`/organizations/${organization.id}/`, body, 'v2')
      .pipe(
        map(response =>
          this.convertService.jsonConvert.deserializeObject(
            response,
            HubtypeOrganizationComplete
          )
        )
      )
  }

  updateName(
    organizationId: string,
    organizationName: string
  ): Observable<HubtypeOrganizationComplete> {
    return this.apiService
      .patch(
        `/organizations/${organizationId}/`,
        { name: organizationName },
        'v2',
        false
      )
      .pipe(
        switchMap((org: any) => {
          this.store.dispatch(
            new organizationActions.UpdateNameAction(org.name)
          )
          return of(org)
        }),
        map(org =>
          this.convertService.jsonConvert.deserializeObject(
            org,
            HubtypeOrganizationComplete
          )
        )
      )
  }

  testWebhook(organization: HubtypeOrganization) {
    if (!organization) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const body = { webhook_url: organization.webhook_url }
    return this.apiService.post(
      `/organizations/${organization.id}/test_webhook/`,
      body,
      {},
      'v2'
    )
  }

  getContactInfoFields(params?: ContactInfoFieldFilter): Observable<{
    count: number
    fields: ContactInfoField[]
  }> {
    return this.apiService
      .get(`/contact-info-fields/?order=-created_at&is_active=true`, params)
      .pipe(
        first(),
        map(field => ({
          count: field.count,
          fields: this.convertService.jsonConvert.deserializeArray(
            field.results,
            ContactInfoField
          ),
        }))
      )
  }

  createContactInfoField(fieldName: string): Observable<ContactInfoField[]> {
    return this.apiService
      .post(
        `/contact-info-fields/`,
        {
          name: fieldName,
        },
        {},
        'v1',
        false
      )
      .pipe(first())
  }

  updateContactInfoField(
    fieldId: string,
    newFieldName: string
  ): Observable<ContactInfoField> {
    return this.apiService
      .patch(
        `/contact-info-fields/${fieldId}/`,
        {
          name: newFieldName,
        },
        'v1',
        false
      )
      .pipe(
        first(),
        map(json =>
          this.convertService.jsonConvert.deserializeObject(
            json,
            ContactInfoField
          )
        )
      )
  }

  deleteContactInfoField(fieldId: string) {
    return this.apiService
      .delete(`/contact-info-fields/${fieldId}/`)
      .pipe(first())
  }

  private assembleOrganization(
    pieces: OrganizationFieldsResponses
  ): HubtypeOrganizationComplete {
    const [myOrg, templates, templateFolders, bots, users, projects] = pieces
    const organization = myOrg.toOrganizationComplete()
    organization.templates = templates
    organization.template_folders = templateFolders
    organization.bots = bots
    organization.users = users
    organization.projects = projects
    return organization
  }
}
